import { LogLevel } from '@azure/msal-browser';
import { b2cPolicies } from './policies';
import { apiConfig } from './apiConfig';

export const msalConfig = {
  auth: {
    clientId: window.REACT_APP_MSAL_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignin.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: window.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  },
  system: {
    allowRedirectInIframe: true,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
        case LogLevel.Error:
          console.error(message);
          return;	
        case LogLevel.Warning:		
          console.warn(message);		
          return;
        default:
          return;
        }
      }
    }
  }
};

export const loginRequest = {
  scopes: [...apiConfig.b2cScopes],
  prompt: 'login',
  state: ''
};

export const tokenRequest = {
  scopes: [...apiConfig.b2cScopes],
  forceRefresh: false
};

export const forgotPasswordRequest  = {
  authority: b2cPolicies.authorities.passwordReset.authority,
  state: ''
}