import { useState } from 'react';
import { InputElementProps } from '../input-interface';
import { ControllerRenderProps, FieldValues, useController, useForm } from 'react-hook-form';

import './textfield.scss';
import { classNameError, errorMessage } from '../../../../helpers/helper-errors';

const TextField = ({
  name,
  control,
  formValue,
  label,
  register,
  errors,
  required,
  helper,
  type,
  validationSchema,
  validation,
  className,
  setError,
  ...props
}: InputElementProps) => {
  let textfield: ControllerRenderProps<FieldValues, string>;
  if (control) {
    const { field } = useController({
      name: `${name}`,
      control: control,
      rules: { required: required },
    });
    textfield = field;
  }

  const [value, setValue] = useState(
    formValue && formValue?.entityValues?.filter((i) => i.id === name)[0]?.value,
  );

  return (
    <>
      <label
        id={`${name}-hint`}
        className={`${className} govuk-label govuk-label--s`}
        htmlFor={name}
      >
        {label}
      </label>
      {helper && (
        <div
          id="format-example-hint"
          className="govuk-label"
          dangerouslySetInnerHTML={{ __html: helper }}
        />
      )}
      <input
        {...register(
          `${name}`,
          (() => {
            switch (validation) {
              case 'nhsNumber':
                return {
                  required: required,
                  pattern: {
                    value: /^\d{10}$/,
                    message: 'NHS number is not valid',
                  },
                };
              case 'nationalInsuranceNumber':
                return {
                  required: required,
                  pattern: {
                    value:
                      /^(?!BG)(?!GB)( )?(?!NK)(?!KN)( )?(?!TN)(?!NT)( )?(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])( )?(?:\s*\d\s*){6}([A-D]|\s){1}?$/i,
                    message: 'Enter a National Insurance number in the correct format',
                  },
                };
              case 'phoneNumber':
                return {
                  required: true,
                  pattern: {
                    value: /^[0-9]{10,11}$/,
                    message: 'Phone number is not valid',
                  },
                };
              case 'emailAddress':
                return {
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: 'Email address is not valid',
                  },
                };
              case 'RentNonDependentHCC':
                return {
                  required: true,
                  pattern: {
                    value: /^[0-9]{1,10}$/,
                    message: 'Numerical value required',
                  },
                };
              case 'childrenHouse':
                return {
                  required: true,
                  pattern: {
                    value: /^[0-9]{1,10}$/,
                    message: 'Numerical value required',
                  },
                };
              case 'numericOnly':
                return {
                  required: required,
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'This field must contain only numbers',
                  },
                };
              case 'percentage':
                return {
                  required: required,
                  pattern: {
                    value: /^(100|[1-9]?\d)$/,
                    message: 'This field must contain a valid percentage (e.g.: 25)',
                  }
                };
              default:
                return {
                  required: required,
                  pattern: required
                    ? {
                        value: /^\s*\S.*$/,
                        message: 'This field is required',
                      }
                    : undefined,
                };
            }
          })(),
        )}
        id={name}
        role="text"
        aria-label={name}
        aria-describedby={`${name}-hint`}
        aria-required="true"
        aria-invalid={errors[`${name}`] ? 'true' : 'false'}
        aria-errormessage={`${name}-err`}
        type={type}
        name={name}
        value={value}
        onChange={(e) => {
          name === 'childrenHouse'
            ? setValue(e.currentTarget.value.replace(/\D/, ''))
            : setValue(e.currentTarget.value);
          if (textfield) {
            textfield.onChange(e.currentTarget.value);
          }
        }}
        maxLength={props.properties.max}
        className={`govuk-input 
        ${classNameError(`${name}`, required!, errors)} ${
          name === 'childrenHouse' ? 'numerical' : ''
        }`}
        {...props}
      />
      <span
        id={`${name}-err`}
        aria-live="assertive"
        data-testid={`${name}-error-msg`}
        className="govuk-error-message"
      >
        {errorMessage(name!, 'This field is required', 'required', required!, errors)}
        {errorMessage(name!, errors[name!]?.message, 'pattern', true, errors)}
      </span>
    </>
  );
};

export default TextField;
