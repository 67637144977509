import { lazy, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAppDispatch } from '../../../hooks/useTypedSelector';
import { FormAssessmentResponse } from '../../../models/forms/form-assessment-response.model';
import { QuestionModel } from '../../../models/formSchema/question';
import { RootModel } from '../../../models/formSchema/root';
import { StepModel } from '../../../models/formSchema/step';
import { submitForm } from '../../../slices/form/form-assessment-slice';
import Button from '../elements/buttons/button';
import { TenantModel } from '../../../models/tenants/tenant-model';
import { getTenantFileConfig } from '../../../slices/tenants/tenant-slice';

import './style.scss';
import LoadingSpinner from '../../loader/loader';
import { FormSubmitRequest } from '../../../models/forms/form-submit-request';
import { SubjectlessFormId } from '../../../constants/form-constants';

const Confirmation = lazy(() => import('./confirmation'));

export interface IProps {
  handleStep: (value: number) => void;
  formData: RootModel;
  formAssessment: FormAssessmentResponse;
  tenant: TenantModel;
  paragraph: string[];
  urn: { id: string; value: string }[];
  backButtonText?: string;
  backButtonPath?: string
}

const OverviewMenu = ({ handleStep, formData, formAssessment, tenant, paragraph, urn, backButtonText, backButtonPath }: IProps) => {
  const dispatch = useAppDispatch();
  let allMandatoryFields: any[];
  let currentFormFields: any[];
  let completedFields: any[];
  let assessmentStatuses = new Array<string>();
  const [reference, setReference] = useState<string>('');
  const tenantName = tenant?.name;
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const isSubjectless = formData?.form.id === SubjectlessFormId

  useEffect(() => {
    if (formData?.form.questions.find((q) => q.type == 'fileuploadfield')) {
      dispatch(getTenantFileConfig())
        .unwrap()
        .then((obj) => {
          window.sessionStorage.setItem('fileSize', String(obj.fileSize));
          window.sessionStorage.setItem('fileType', obj.fileType.join(','));
        })
        .catch((err) => {
          console.error('Error:', err);
        });
    }
  }, [formData]);

  const routeChange = () => {
    const path = backButtonPath ?? `/${tenantName}/${isSubjectless
      ? 'subjectless-dashboard' : 'dashboard'}`;
    navigate(path);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (reference) {
    return <Confirmation reference={reference} tenant={tenant} />;
  }

  const renderStatus = (steps: StepModel[], questions: QuestionModel[]) => {
    let statusColour = 'govuk-tag govuk-tag--grey';
    let status = 'NOT STARTED';
    let entitiesIds = new Array<string>();

    if (formAssessment) {
      for (let i = 0; i < steps.length; i++) {
        const ids = steps[i].entities.map((entity) => entity.id);
        entitiesIds = entitiesIds.concat(ids);

        if (steps[i].conditions !== null) {
          const conditionIsNotNull = formAssessment.entityValues
            .filter((e) => e.id == steps[i].conditions[0].questionId)
            .filter((e) => e.value == String(steps[i].conditions[0].value));

          if (conditionIsNotNull.length == 0) {
            const conditionalIds = steps[i].entities.map((e) => e.id);
            entitiesIds = entitiesIds.filter((id) => !conditionalIds.includes(id));
          }
        }
        
        if (steps[i].skipToSummaryConditions !== null
          && steps[i].skipToSummaryConditions
            .every((x) => formAssessment.entityValues
              .find(e => e.id == x.questionId)?.value == String(x.value))) {
          break;
        }
      }

      allMandatoryFields = questions
        ?.filter((q) => q.mandatory && q.type !== 'datefield')
        .map((q) => ({ ...q, mandatoryId: entitiesIds?.includes(q.id) }));
      allMandatoryFields = allMandatoryFields.filter((mf) => !mf.id.toLowerCase().includes('address'));

      currentFormFields = formAssessment?.entityValues
        ?.filter((e) => e.value)
        .map((e) => ({ ...e, fieldId: entitiesIds?.includes(e.id) }));

      const mandatoryIds = allMandatoryFields.filter((mf) => mf.mandatoryId).map((mf) => mf.id);

      completedFields =
        currentFormFields &&
        currentFormFields
          ?.filter((ff) => ff.value && ff.type !== 'datefield')
          .map((ff) => ({ ...ff, completedId: mandatoryIds.includes(ff.id) }))
          .filter((item) => item.completedId === true);

      if (currentFormFields?.filter((cff) => cff.fieldId).length > 0) {
        status = 'IN PROGRESS';
        statusColour = 'govuk-tag govuk-tag--blue';
      }

      const mandatorySubFields = allMandatoryFields?.filter((mf) => mf.mandatoryId);

      const declarationFields = mandatorySubFields
        .filter(field => field.options.find((options: any) => options.declaration == true));
      
      let consentGrantedForAllDeclarationFields = true;

      declarationFields.forEach(declarationField => {
        const completedField = completedFields.find((field: any) => field.id == declarationField.id);
        const expectedOption = declarationField.options.find((option: any) => option.declaration == true);

        if (!completedField) {
          consentGrantedForAllDeclarationFields = false;
        }
        else if (expectedOption.value !== completedField.value) {
          consentGrantedForAllDeclarationFields = false;
        }
      });

      if (completedFields?.length > 0 && mandatorySubFields?.length === completedFields?.length) {
        if (!consentGrantedForAllDeclarationFields) {
          assessmentStatuses = assessmentStatuses.concat(status);
        } else {
          status = 'COMPLETED';
          statusColour = 'govuk-tag govuk-tag--green';
        }
      }
      
      assessmentStatuses = assessmentStatuses.concat(status);
    }

    return (
      <dd
        id={`dd-${status.toLocaleLowerCase().replace(' ', '')}`}
        className="govuk-summary-list__actions"
        aria-label={status}
      >
        <strong id={status.toLocaleLowerCase().replace(' ', '')} className={statusColour}>
          {status}
        </strong>
      </dd>
    );
  };

  const renderMenu = () => {
    return formData?.form.layout.sections.map((menu, idx) => {
      return (
        <div key={idx} className="govuk-summary-list__row">
          <dt className="govuk-summary-list__key">
            <Link
              id={menu.title.toLocaleLowerCase().replace(' ', '')}
              className="govuk-link govuk-link--no-underline"
              to={`/${tenantName}/${window.location.pathname.split('/' + String(tenantName) + '/')[1]}`}
              onClick={() => handleStep(idx)}
              aria-label={menu.title}
            >
              {menu.title}
            </Link>
          </dt>
          {renderStatus(menu.steps, formData?.form.questions)}
        </div>
      );
    });
  };

  const showLoader = () => {
    setLoading(true);
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    const request: FormSubmitRequest = {
      entityValues: formAssessment.entityValues.map((entity) => {
        if (urn.find((x) => x.id == entity.id))
          return { ...entity, value: urn.find((x) => x.id == entity.id)!.value };
        return entity;
      }),
      formId: formAssessment.formId,
      schemaId: formAssessment.schemaId,
    };

    const args = {
      schemaId: formAssessment.schemaId,
      submitFormRequest: request,
    };

    dispatch(submitForm(args))
      .unwrap()
      .then((obj) => {
        setReference(obj);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error', err);
        toast.error('There was an error processing your request, please try again');
      });
  };

  const isCompleted = () => {
    let countCompleteSection = 0;

    for (let i = 0; i < assessmentStatuses.length; i++) {
      if (assessmentStatuses[i] === 'COMPLETED') countCompleteSection++;
    }

    if (countCompleteSection === formData?.form.layout.sections.length) return false;

    return true;
  };

  return (
    <>
      <div id="fa-overview-menu" className="govuk-main-wrapper " role="main">
        <h1 className="govuk-heading-l">{formData?.form.name}</h1>
        <div dangerouslySetInnerHTML={{ __html: paragraph.toString() }} />
        {tenantName ? (
          <>
            <dl className="govuk-summary-list">{renderMenu()}</dl>
            <div className="govuk-!-margin-top-4">
              <div className="govuk-grid-row govuk-!-margin-top-7">
                <div className="govuk-grid-column-one-half govuk-!-padding-0 flex__text-align--left">
                  <Button
                    id="back"
                    label={backButtonText ?? 'Go back to Dashboard'}
                    disabled={false}
                    type="submit"
                    onClick={routeChange}
                  />
                </div>
                {isSubjectless ? '' : <div className="govuk-grid-column-one-half govuk-!-padding-0 flex__text-align--right">
                  <Button
                    id="submit"
                    label="Submit"
                    disabled={isCompleted()}
                    type="submit"
                    onClick={(e) => {
                      showLoader(), handleSubmit(e);
                    }}
                  />
                </div>}
              </div>
            </div>
          </>
        ) : (
          <LoadingSpinner />
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default OverviewMenu;
