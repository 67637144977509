import { UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import './hello-user-icon.scss';

type Props = {
  id: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  isCitizen: boolean;
};

function HelloUserIcon({ id, firstName, lastName, isCitizen }: Props) {
  const mobileView = window.innerWidth < 500 ? true : false;
  const wrapperClass = mobileView ? 'header_mobile_view' : undefined;

  return (
    <div id={id} className={wrapperClass}>
      { isCitizen ? (
        <>
          <UserSwitchOutlined className="user_icon" />
          {'Acting on behalf of:'}<br /><div className='acting_on_name'>
            {`${firstName} ${lastName}`}</div>
        </>
      ) : (
        <>
          <UserOutlined className="user_icon" />
          {`Hello, ${firstName} ${lastName}`}
        </>
      )}
    </div>
  );
}

export default HelloUserIcon;
