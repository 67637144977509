import { useEffect, useState } from 'react';
import { InputElementProps } from '../input-interface';
import './file-upload-field.scss';

const FileUploadField = ({
  name,
  label,
  register,
  errors,
  required,
  type,
  validationSchema,
  className,
  setError,
  clearErrors,
  formValue,
  setFile,
  setFilePropName,
  setValue,
  getValues,
  setFileNameToDelete,
  ...props
}: InputElementProps) => {
  const [isFileForUpload, setIsFileForUpload] = useState(false);
  const [fileName, setFileName] = useState(formValue?.entityValues?.find(x => x.id == name)?.value);
  const [key, setKey] = useState(0);
  const fileSize = window.sessionStorage.getItem('fileSize');
  const fileTypes: string = props.properties && props.properties.allowedExtensions
    ? props.properties.allowedExtensions
    : window.sessionStorage.getItem('fileType');

  const size = parseInt(fileSize!) / 1000000;
  const requirement = (required && fileName) ? {} : validationSchema;

  const [fileUploadEntities, setFileUploadEntities] = useState<{ name: string, value: string }[]>([]);

  const fileIdRegex  = new RegExp(`^${name}\\d*$`);

  useEffect(() => {
    const filteredFileUploadEntities = formValue?.entityValues?.filter((entity: any) =>
      fileIdRegex .test(entity.id) && entity.value && entity.value !== 'No file uploaded'
    ).map((entity: any) => ({ name: entity.id, value: entity.value })) || [];
    setFileUploadEntities(filteredFileUploadEntities);
  }, [formValue]);

  const handleFileInput = (e: any) => {
    const fileUploadObj = e.target.files[0];
    const storedFileName = formValue?.entityValues?.find(x => x.id == name)?.value;

    if (storedFileName) setFileNameToDelete?.(storedFileName);

    if (e.target.files.length < 1) {
      setFile(null);
      setFileName(storedFileName);
      if (errors?.[name!]) clearErrors?.(name);
      setFileNameToDelete?.('');
      return;
    }
    setFileName(fileUploadObj.name);
    setIsFileForUpload(true);

    if (fileUploadObj.size == 0) {
      e.target.value = '';
      setError(name!, {
        type: 'filetype',
        message: 'The selected file is empty'
      });
      return;
    } else if (fileUploadObj.size > parseInt(fileSize!)) {
      e.target.value = '';
      setError(name!, {
        type: 'filetype',
        message: `The selected file must be smaller than ${size}MB`
      });
      return;
    } else if (!hasExtension(fileUploadObj.name, fileTypes)) {
      e.target.value = '';
      setError(name!, {
        type: 'filetype',
        message: `The selected file must be a ${fileTypes.replaceAll('.', '')
          .replaceAll(',', ', ').toUpperCase()}`
      });
      return;
    } else {
      if (errors?.[name!]) clearErrors?.(name);
      setFile(fileUploadObj);
      setFilePropName(name!);

      if (formValue?.entityValues) {
        const updatedEntityValues = formValue.entityValues.map((entity: any) =>
          entity.id === name ? { ...entity, value: fileUploadObj.name } : entity
        );
        setValue('entityValues', updatedEntityValues);

        setFileUploadEntities([...fileUploadEntities, { name: name!, value: fileUploadObj.name }]);
      }
    }
  };

  const handleRemoveFile = (fileNameToRemove: string) => {
    setFileName('');
    setIsFileForUpload(false);
    setValue(name!, '');
    setFileNameToDelete?.(fileNameToRemove);

    if (formValue?.entityValues) {
      const updatedEntityValues = formValue.entityValues.map((entity: any) =>
        entity.id === name ? { ...entity, value: 'No file uploaded' } : entity
      );
      setValue('entityValues', updatedEntityValues);

      setFileUploadEntities(updatedEntityValues.filter((entity: any) =>
        fileIdRegex .test(entity.id) && entity.value && entity.value !== 'No file uploaded'
      ).map((entity: any) => ({ name: entity.id, value: entity.value })));
    }
  };

  const hasExtension = (fileName: string, exts: string) => {
    const validExtensions = exts.split(',');
    return (new RegExp('(' + validExtensions.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
  };

  return (
    <>
      <label
        id={`${name}-hint`}
        className={`${className} govuk-label govuk-label--s`}
        htmlFor={name}
      >
        {label}
      </label>
      <div className="fileUploadField">
        {fileName != 'No file uploaded' && (
          <>
            <label
              id={`${name}-hint`}
              className={`${name} govuk-label govuk-label--s`}
              htmlFor={name}
            >
              {fileName ? fileName : ''}
            </label>
            {fileName && (
              <button
                type="button"
                className="removeFileButton"
                onClick={() => handleRemoveFile(fileName)}
              >
                &times;
              </button>
            )}
          </>
        )}
      </div>
      <input
        {...register(name!, requirement)}
        id={name}
        key={key}
        aria-label={name}
        aria-describedby={`${name}-hint`}
        aria-controls={name}
        aria-invalid={errors[`${name}`] ? 'true' : 'false'}
        aria-errormessage={`${name}-err`}
        type={type}
        accept={fileTypes}
        name={name}
        onChange={(e) => handleFileInput(e)}
        className={`govuk-file-upload
          ${(!errors[name!] && '') || (errors[name!] && 'govuk-input--error')}`}
        {...props}
      />
      <span
        id={`${name}-err`}
        aria-live="assertive"
        data-testid={`${name}-error-msg`}
        className="govuk-error-message"
      >
        {errors[name!] && errors[name!].type === 'required' && 'This field is required'}
        {errors[name!] && errors[name!].type === 'filetype' && errors[name!].message}
      </span>
      <p className="govuk-!-padding-top-2">Your file must be: </p>
      <p></p>
      <ul className="govuk-list govuk-list--bullet">
        <li>a {fileTypes.replaceAll('.', '').replaceAll(',', ', ').toUpperCase()}</li>
        <li>smaller than {size}MB</li>
      </ul>
      <div>
        <table className="govuk-table">
          <thead className="govuk-table__head">
            <tr className="govuk-table__row">
              <th className="govuk-table__header">All Uploaded Documents:</th>
            </tr>
          </thead>
          <tbody className="govuk-table__body">
            {fileUploadEntities.map((entity) => (
              <tr key={entity.name} className="govuk-table__row">
                <td className="govuk-table__cell">{entity.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FileUploadField;